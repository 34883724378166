<template>
    <b-card id="SingleError" class="p-2" :class="$route.meta.layout == 'full' ? 'w-75 my-4 mx-auto': '' ">        
        <header class="flex items-center justify-between mb-8">
            <div>
                <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 inline-block">{{name}}</h1>
                <span v-if="level=='hard'" class="border-red-600 border rounded p-1 ml-2 uppercase text-white bg-red-600 font-bold inline-block">Importante</span>
                <span v-if="level=='medium'" class="border-yellow-600 border rounded p-1 ml-2 uppercase text-white bg-yellow-600 font-bold inline-block">Medio</span>
            </div>            
            <b-button :to="$route.meta.layout == 'full' ? '/publico/listado-alertas' : '/alertas'" class="p-1" variant="primary">Listado</b-button>
        </header> 
        <section class="body-font" >
            <p v-html="description"></p>
        </section>
        <section class="body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
            <template v-for="(l, i) in steps">
                <div class="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto" :key="i">
                    <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-500 text-white relative z-10 title-font font-medium text-sm">{{i+1}}</div>
                    <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                            <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">{{l.title}}</h2>
                            <p class="leading-relaxed" v-html="l.description"></p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        </section>
        <section class="text-gray-600 body-font">
            <p v-html="conclusion"></p>
        </section>
    </b-card>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import firebase from 'firebase/app'
import 'firebase/database'
export default {
    data (){
        return{
            data: null,
            lt: 0,
            count:0,
            name: '',
            description: '',
            steps: null,
            conclusion: '',
            level: ''
        }
    },
    components:{
        BCard,BButton
    },
    created(){
        const id = this.$route.params.idError        
        firebase.database().ref('errors/'+id).on('value', (s)=>{
            this.data = s.val()
            this.name = s.val().name
            this.description = s.val().description
            this.steps = s.val().steps
            this.conclusion = s.val().conclusion
            this.level = s.val().level
        })        
    }    
}
</script>
<style>
#SingleError a{
    color: #fe7018;
    font-weight: bold;
    border: 1px #fe7018 dotted;
    padding: 2px 2px;
}
#SingleError a:hover{
    color: #fff;    
    background: #fe7018;        
}
</style>